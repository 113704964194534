import React, { FC } from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout from 'components/Layout';
import Banner from 'components/Banner';
import FaqBanner from 'components/FaqBanner';
import SymptomsIllustrations from 'components/SymptomsIllustrations';
import FeaturedArticles from 'components/FeaturedArticles';
import ChildrenProductTab from 'components/ChildrenProductTab';
import NFBodyRenderer, { IExpectedStructures } from 'components/common/NFBodyRenderer';
import FeaturedProducts from 'components/FeaturedProducts';
import ExtendedFeaturedArticles from 'components/ExtendedFeaturedArticles';
import DosageInfo from 'components/DosageInfo';

import { IUmbracoChildrenHubIncomeData, IUmbracoChildrenHubParsedData } from './models';
import parseUmbracoChildrenHubIncomeData from './parsers';
import './ChildrenHubPage.scss';

interface IPropsChildrenHubPage extends PageProps {
  pageContext: { breadcrumbs: UmbracoTypes.IBreadCrumb[]; featuredProductsLinks: string[] };
  data: IUmbracoChildrenHubIncomeData;
}

const ExpectedStructures: IExpectedStructures = {
  'Page Banner': {
    Component: Banner,
    dataKey: 'banner',
  },
  'Symptoms List': {
    Component: SymptomsIllustrations,
    dataKey: 'symptomsList',
  },
  'Featured Articles': {
    Component: FeaturedArticles,
    dataKey: 'relatedArticles',
  },
  'Featured Products': {
    Component: FeaturedProducts,
    dataKey: 'featuredProducts',
  },
  'Links List': {
    Component: ChildrenProductTab,
    dataKey: 'linksList',
  },
  'FAQ Banner': {
    Component: FaqBanner,
    dataKey: 'faqBanner',
  },
  'Featured Articles From Symptoms & Pain Advice Sections': {
    Component: ExtendedFeaturedArticles,
    dataKey: 'extendedFeaturedArticles',
  },
  'Dosage Info Section': {
    Component: DosageInfo,
    dataKey: 'dosageInfoSection',
  },
};

const ChildrenHubPage: FC<IPropsChildrenHubPage> = (props) => {
  const parsedData: IUmbracoChildrenHubParsedData = parseUmbracoChildrenHubIncomeData({
    ...props.data,
    breadCrumbs: props.pageContext.breadcrumbs,
    featuredProductsLinks: props.pageContext.featuredProductsLinks,
  });

  const {
    allUmbracoChildrenHub: {
      nodes: [
        {
          seoMetaTitle,
          seoMetaDescription,
          seoMetaKeywords,
          seoExternalHreflangs,
          ogImage,
          lang,
          pageName,
        },
      ],
    },
    header,
    footer,
    siteSettings,
    covidBanner,
    newsletterPopupDefault,
    newsletterPopupDiscount,
    newsletterPopupFreeDelivery,
    disclaimerPopup,
    promoModal,
    searchPath,
    brandSettings,
    legalPopup,
    pageLangs: { nodes: langs },
  } = props.data;

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        ogImage,
      }}
      header={header}
      footer={footer}
      siteSettings={siteSettings}
      covidBanner={covidBanner}
      newsletterPopupDefault={newsletterPopupDefault}
      newsletterPopupDiscount={newsletterPopupDiscount}
      newsletterPopupFreeDelivery={newsletterPopupFreeDelivery}
      disclaimerPopup={disclaimerPopup}
      searchPath={searchPath}
      brandSettings={brandSettings}
      langSettings={{
        currentLang: lang,
        langs,
      }}
      legalPopup={legalPopup}
      promoModal={promoModal}
      className="children-hub-page"
    >
      <NFBodyRenderer
        bodyStructure={props.data.allUmbracoChildrenHub.nodes[0].body}
        expectedStructures={ExpectedStructures}
        parsedData={parsedData}
        additionalBodyProps={{ listingName: pageName }}
      />
    </Layout>
  );
};

export const query = graphql`
  query(
    $lang: String!
    $link: String = ""
    $articleTagId: Int
    $featuredProductsLinks: [String]
    $pageBannerImageSize: Int = 560
    $pageIdRegex: String
    $extendedFeaturedArticlesByTagIds: [Int]
    $extendedFeaturedArticlesByLinks: [String]
  ) {
    ...FragmentExtendedFeaturedArticlesData
    featuredArticlesByTag: allUmbracoArticles(
      filter: { tags: { elemMatch: { id: { eq: $articleTagId } } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentFeaturedArticleData
      }
    }
    featuredProducts: allUmbracoProduct(
      filter: { link: { in: $featuredProductsLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentProductCard
      }
    }
    allUmbracoChildrenHub(filter: { lang: { eq: $lang }, link: { eq: $link } }) {
      nodes {
        ...FragmentSeo
        lang
        pageName
        body {
          structure
          properties {
            ...FragmentBanner
            ...FragmentDosageInfoSection
            ...FragmentSymptomsIllustrations
            ...FragmentFeaturedArticles
            ...FragmentFeaturedProducts
            ...FragmentChildrenProductTab
            ...FragmentFAQBanner
            ...FragmentExtendedFeaturedArticles
          }
        }
      }
    }
    carouselControls: siteSettings(lang: { eq: $lang }) {
      ariaLabelNext
      ariaLabelPrev
    }
    pageLangs: allUmbracoChildrenHub(filter: { umbracoId: { regex: $pageIdRegex } }) {
      nodes {
        link
        lang
      }
    }
    ...FragmentCommonCompositions
  }
`;

export default ChildrenHubPage;
