import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Container from 'react-bootstrap/Container';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import NFButton from 'components/common/NFButton';

import { IPropsChildrenProductTab } from './model';
import './ChildrenProductTab.scss';

const ChildrenProductTab: FC<IPropsChildrenProductTab> = ({ title, buttons }) => (
  <Container data-test="ChildrenProductTab" fluid>
    <div className="nf-children-product-tab">
      <DangerouslySetInnerHtml
        element="h2"
        className="nf-children-product-tab__title"
        html={title}
      />
      {buttons?.length > 0 ? (
        <div className="nf-children-product-tab__buttons">
          {buttons.map((button) => {
            return <NFButton key={button.btnText} {...button} />;
          })}
        </div>
      ) : null}
    </div>
  </Container>
);

export const query = graphql`
  fragment FragmentChildrenProductTab on ILinksList {
    linksListTitle
    linksListItems {
      properties {
        ...FragmentNFButton
      }
    }
  }
`;

export default ChildrenProductTab;
