import sortBy from 'lodash.sortby';
import ParserHelpers, { IStructureToPropsMapResult } from 'utils/parseHelpers';

import { parseBanner } from 'components/Banner/parsers';
import { parseNFButton } from 'components/common/NFButton/parsers';
import { parseSymptomsList } from 'components/SymptomsIllustrations/parsers';
import { parseExtendedFeaturedArticles } from 'components/ExtendedFeaturedArticles/parsers';
import parseFAQBanner from 'components/FaqBanner/parsers';

import { IUmbracoChildrenHubIncomeData, IUmbracoChildrenHubParsedData } from './models';

const parseUmbracoChildrenHubIncomeData = (
  param: IUmbracoChildrenHubIncomeData
): IUmbracoChildrenHubParsedData => {
  const data: IStructureToPropsMapResult = ParserHelpers.getStructureToPropsMap(
    param.allUmbracoChildrenHub.nodes[0].body
  );
  const featuredArticlesData = data['Featured Articles']?.properties;
  const featuredProductsData = data['Featured Products']?.properties;
  const childrenProductTabData = data['Links List']?.properties;
  const faqBannerData = data['FAQ Banner']?.properties;
  const extendedFeaturedArticles =
    data['Featured Articles From Symptoms & Pain Advice Sections']?.properties;
  const sortedProducts = sortBy(param.featuredProducts.nodes, (item) =>
    param.featuredProductsLinks.indexOf(item.link)
  );
  const dosageInfoSection =
    data['Dosage Info Section']?.properties?.dosageInfoSection?.[0]?.properties;

  const result: IUmbracoChildrenHubParsedData = {
    banner: data['Page Banner']
      ? parseBanner(data['Page Banner'].properties, { breadCrumbs: param.breadCrumbs })
      : undefined,
    symptomsList: data['Symptoms List']
      ? parseSymptomsList(data['Symptoms List'].properties)
      : undefined,
    relatedArticles: featuredArticlesData
      ? {
          bgColor: featuredArticlesData.featuredArticlesBgColor,
          title: featuredArticlesData.featuredArticlesSectionTitle,
          subtitle: featuredArticlesData.featuredArticlesSectionSubtitle,
          btn: featuredArticlesData.featuredArticlesCTAButtonData?.length
            ? { ...parseNFButton(featuredArticlesData.featuredArticlesCTAButtonData[0].properties) }
            : undefined,
          articles: featuredArticlesData.featuredArticlesData,
          mode: featuredArticlesData.featuredArticlesSelectedArticleMode,
          articlesByTag: param.featuredArticlesByTag.nodes,
          carouselControls: param.carouselControls,
        }
      : undefined,
    featuredProducts: featuredProductsData
      ? {
          title: featuredProductsData.featuredProductsSectionTitle || null,
          subtitle: featuredProductsData.featuredProductsSectionSubtitle || null,
          sectionBtn: featuredProductsData.featuredProductsCTASectionButtonData?.length
            ? {
                ...parseNFButton(
                  featuredProductsData.featuredProductsCTASectionButtonData[0].properties
                ),
              }
            : undefined,
          bgColor: featuredProductsData.featuredProductsBgColor,
          enableProductsCTA: featuredProductsData.featuredProductsEnableProductsCTA,
          enableProductsSecondaryText:
            featuredProductsData.featuredProductsEnableProductsSecondaryText,
          responsiveCarouselStructure:
            featuredProductsData.featuredProductsResponsiveCarouselStructure,
          products: sortedProducts,
          cardBtnData: featuredProductsData.featuredProductsCTACardButtonData?.length
            ? {
                text: featuredProductsData.featuredProductsCTACardButtonData[0].properties.text,
                ariaLabel:
                  featuredProductsData.featuredProductsCTACardButtonData[0].properties.ariaLabel,
                btnStyles:
                  featuredProductsData.featuredProductsCTACardButtonData[0].properties.btnStyles,
                icon: featuredProductsData.featuredProductsCTACardButtonIcon,
              }
            : undefined,
          carouselControls: param.carouselControls,
        }
      : undefined,
    linksList: childrenProductTabData
      ? {
          title: childrenProductTabData.linksListTitle,
          buttons: childrenProductTabData?.linksListItems.length
            ? childrenProductTabData.linksListItems.map((item: LinksList.ILinkListItem) =>
                parseNFButton(item.properties)
              )
            : null,
        }
      : undefined,
    extendedFeaturedArticles: extendedFeaturedArticles
      ? parseExtendedFeaturedArticles({
          extendedFeaturedArticles,
          articlesByLink: [
            ...param?.extendedFeaturedArticlesPainAdviceByLink?.nodes,
            ...param?.extendedFeaturedArticlesSymptomsByLink?.nodes,
          ],
          articlesByTag: [
            ...param?.extendedFeaturedArticlesPainAdviceByTag?.nodes,
            ...param?.extendedFeaturedArticlesSymptomsByTag?.nodes,
          ],
          carouselControls: param.carouselControls,
        })
      : undefined,
    faqBanner: faqBannerData ? parseFAQBanner(faqBannerData) : undefined,
    dosageInfoSection: dosageInfoSection
      ? {
          title: dosageInfoSection.title,
          isDosageInfoTwoColumn: dosageInfoSection.isDosageInfoTwoColumn,
          subtitle: dosageInfoSection.subtitle,
          ctaButton: dosageInfoSection.ctaButton,
        }
      : undefined,
  };

  return result;
};

export default parseUmbracoChildrenHubIncomeData;
