import React, { FC, useState, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import Container from 'react-bootstrap/Container';
import { graphql } from 'gatsby';

import NFAccordion from 'components/common/NFAccordion';
import NFButton from 'components/common/NFButton';
import UniversalImage from 'components/common/UniversalImage';
import { parseNameToId } from 'templates/HubPage/parsers';

import { INFAccordionItem } from 'components/common/NFAccordion/model';

import ProductImageGlowEffect from 'components/ProductImageGlowEffect';
import { IPropsFaqBanner } from './models';
import './FaqBanner.scss';

const FaqBanner: FC<IPropsFaqBanner> = ({
  bgBannerColor,
  accordionTitle,
  img,
  imageType,
  imagePosition,
  isImageHiddenOnMobile,
  imageAlt,
  btn,
  dosageTitle,
  accordionItems,
  downloadBtnList,
  downloadIcon,
  downloadIconText,
  downloadIconAlt,
  faqBannerAnchor,
  sectionLandmark,
  sectionNameForSubNav,
}) => {
  const [selectedAccordionIndex, setSelectedAccordionIndex] = useState<number>(-1);

  useEffect(() => {
    if (!accordionItems || !accordionItems.length) {
      return;
    }

    setSelectedAccordionIndex(
      accordionItems.findIndex((item: INFAccordionItem) => item.isOpened === '1')
    );
  }, []);

  const onChangeCallback = useCallback(({ selectedIndex }) => {
    setSelectedAccordionIndex(selectedIndex);
  }, []);

  const bgColor = bgBannerColor ? ` ${bgBannerColor}-default-bg` : '';

  const classes = classNames('nf-children-faq-banner', {
    [`${imageType === 'svg' ? 'nf-children-faq-banner--img-offset' : ''}`]: imageType,
    [`nf-children-faq-banner--img-hidden-on-mobile`]: isImageHiddenOnMobile,
    [bgColor]: bgColor,
  });
  const classesAccordion = classNames('nf-children-faq-banner__faq-accordion', {
    [bgColor]: bgColor,
  });

  const classesImage = classNames('nf-children-faq-banner__img', {
    active: selectedAccordionIndex > -1,
    [`nf-children-faq-banner__img--${imageType}`]: imageType,
    [`nf-children-faq-banner__img--hidden-on-mobile`]: isImageHiddenOnMobile,
  });

  const classesBannerFrame = classNames('nf-children-faq-banner__frame', {
    [`nf-children-faq-banner__frame--${imagePosition}`]: imagePosition,
  });

  return (
    <section
      data-test="ChildrenFaqBanner"
      className={classes}
      {...(faqBannerAnchor ? { id: faqBannerAnchor } : {})}
      aria-label={sectionLandmark}
      id={parseNameToId(sectionNameForSubNav)}
    >
      <Container fluid>
        <div className={classesBannerFrame}>
          {img || dosageTitle || btn ? (
            <div className="nf-children-faq-banner__product-name">
              {img && imageType === 'svg' ? (
                <UniversalImage imageAlt={imageAlt} img={img} wrapperClassName={classesImage} />
              ) : null}
              {img && imageType === 'png' ? (
                <ProductImageGlowEffect img={img} imgAlt={imageAlt || ''} />
              ) : null}
              <div className="nf-children-faq-banner__name">
                {dosageTitle ? (
                  <h2 className="nf-children-faq-banner__product-title">{dosageTitle}</h2>
                ) : null}

                {btn ? <NFButton {...btn} /> : null}
              </div>
            </div>
          ) : null}
          {accordionItems?.length && accordionTitle ? (
            <div className={classesAccordion}>
              <h2 className="nf-children-faq-banner__product-title">{accordionTitle}</h2>
              {accordionItems && accordionItems.length ? (
                <NFAccordion
                  onChange={onChangeCallback}
                  changeOnClick={false}
                  items={accordionItems}
                  selectedIndex={selectedAccordionIndex}
                />
              ) : null}
              {downloadBtnList && downloadBtnList.length
                ? downloadBtnList.map((item: NFButton.INFBtnParsedData) => {
                    if (!item.btnText || !item.gatsbyDocFilePath) {
                      return null;
                    }

                    return (
                      <a
                        key={item.btnText}
                        className="nf-children-faq-banner__download-btn"
                        href={item.gatsbyDocFilePath}
                        download={item.btnText}
                        aria-label={item.ariaLabel}
                      >
                        <span>{item.btnText}</span>
                        <span className="nf-children-faq-banner__icon">
                          {downloadIcon ? (
                            <UniversalImage imageAlt={downloadIconAlt} img={downloadIcon} />
                          ) : null}
                          {downloadIconText || ''}
                        </span>
                      </a>
                    );
                  })
                : null}
            </div>
          ) : null}
        </div>
      </Container>
    </section>
  );
};

export const query = graphql`
  fragment FragmentFAQBanner on IFAQBanner {
    faqBannerDosageTitle
    faqBannerAccordionTitle
    faqBannerAccordionItems {
      ...FragmentAccordionCommonWithProps
    }
    faqBannerCTAButton {
      properties {
        ...FragmentNFButton
      }
    }
    faqBannerBgColour {
      ...FragmentColorProps
    }
    faqBannerDownloadButtons {
      properties {
        ...FragmentNFButton
      }
    }
    faqBannerImageAlt
    faqBannerIsImageHiddenOnMobile
    faqBannerImage {
      fallbackUrl
      svg {
        content
      }
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    faqBannerImageType
    faqBannerImagePosition
    faqBannerDownloadIcon {
      fallbackUrl
      svg {
        content
      }
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 50) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    faqBannerDownloadIconText
    faqBannerDownloadIconAlt
    faqBannerAnchor
    sectionLandmark
    sectionNameForSubNav
  }
`;

export default FaqBanner;
